export default [
  {
    path: '/',
    name: 'Auth',
    redirect: {name: 'Login'},
    component: () => import('@/layouts/AuthLayout'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
        meta: {
          title: 'Logowanie',
          public: true,
        },
      },
      {
        path: 'remind-password',
        name: 'RemindPassword',
        component: () => import('@/views/auth/RemindPassword'),
        meta: {
          title: 'Przypomnienie hasła',
          public: true,
        },
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
          title: 'Resetowanie hasła',
          public: true,
        },
      },
      {
        path: 'activate-account',
        name: 'ActivateAccount',
        component: () => import('@/views/auth/ActivateAccount'),
        meta: {
          title: 'Aktywacja konta',
          public: true,
        },
      }
    ]
  }
]
