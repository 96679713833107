export const getToken = () => localStorage.getItem('token')

export const setToken = (token) => localStorage.setItem('token', token)


export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = (key) => {
  try {
    const token = localStorage.getItem(key)
    
    if (token) {
      return JSON.parse(token)
    }
    return ''
  } catch(err) {
    return ''
  }
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key)
}
