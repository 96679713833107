import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from "@/router/authRoutes";
import authenticatedRoutes from "@/router/authenticatedRoutes";
import {getToken} from "@/utils/storage";

Vue.use(VueRouter)

const routes = [
  ...authenticatedRoutes,
  ...authRoutes,
  {
    path: '*',
    redirect: {name: 'Auth'},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLogged = !!getToken()
  const isAuthenticatedRoute = to.matched[0]?.name === 'Authenticated'
  const canContinue = isLogged === isAuthenticatedRoute

  if (!canContinue) {
    next({name: isLogged ? 'Authenticated' : 'Auth'})
  }

  next()
})

export default router
